import React from 'react'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'

import Layout from 'components/Layout'

export default ({ data }) => (
  <Layout pageTitle='Travsport och ridsport'>
    <oma-grid-row>
      <section>
        <h1>Travsport och ridsport</h1>
        <p>Sidan är under uppbyggnad. Kom gärna tillbaka om några dagar.</p>
        <p>
          Vi är återförsäljare på följande vagnsmärken: Custom, Degato,
          Desperado, Royal, USAi.
        </p>
        <div className='carts'>
          <Img fluid={data.custom.childImageSharp.fluid} />
          <Img fluid={data.degato.childImageSharp.fluid} />
          <Img fluid={data.desperado.childImageSharp.fluid} />
          <Img fluid={data.royal.childImageSharp.fluid} />
          <Img fluid={data.usai.childImageSharp.fluid} />
        </div>
      </section>
    </oma-grid-row>
  </Layout>
)

export const query = graphql`
  query {
    custom: file(relativePath: { eq: "trotting/custom-mf-wood-sulky.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    degato: file(relativePath: { eq: "trotting/degato-race-sulky-qh.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    desperado: file(
      relativePath: { eq: "trotting/desperado-sulky-long-std.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    royal: file(relativePath: { eq: "trotting/royal-king-st-us-sulky.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    usai: file(relativePath: { eq: "trotting/usai-arrow-sulky.png" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
